import * as React from "react";
import { useState, useEffect, useRef } from "react";
import "../CustomStyle.css";
import CustomDataGrid from "../components/CustomDataGrid";
import EntityDetails from "../components/EntityDetails";
import axios from "axios";
import { Spinner, Alert } from "react-bootstrap";

export default function CampaignsListGrid({ secretKey }) {
  const isInitialRender = useRef(true);
  const isInitialRenderVisit = useRef(true);

  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignError, setCampaignError] = useState(null);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [campaignTotalRows, setCampaignTotalRows] = useState(0);

  const [records, setRecords] = useState([]);
  const [respondError, setRespondError] = useState(null);
  const [respondInfo, setRespondInfo] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [campaignName, setCampaignName] = useState(null);
  const [respondedTotalRows, setRespondedTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const [visits, setVisits] = useState([]);
  const [visitError, setVisitError] = useState(null);
  const [visitsTotalRows, setVisitsTotalRows] = useState(0);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [apiError, setAPIError] = useState(null);

  // const [secretKey, setSecretKey] = useState(
  //   window.location.hash.replace("#", "")
  // );
  // let secretKey = window.location.hash.replace("#", "");

  const APIENDPOINT = "developer19-linqr.reachpersona.com";
  console.log("outside secret", secretKey);

  // fetch campaigns data once and integrate to data grid initially
  useEffect(() => {
    setCampaignError(null);
    setCampaignInfo(null);

    // if (!secretKey) {
    //   const loginCode = sessionStorage.getItem("login_code");
    //   if (!loginCode) {
    //     setAPIError("Please visit the URL provided to you.");
    //   } else {
    //     setSecretKey(loginCode);
    //     console.log("secret else block", secretKey);
    //   }
    // } else {
    //   sessionStorage.setItem("login_code", secretKey);
    // }

    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/campaigns`, {
          params: {
            secret_key: secretKey,
          },
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setCampaignsData(data.records);
            setCampaignTotalRows(data.records.length);
            break;

          case 400:
            setCampaignError(
              "Something went wrong while attempting to display data on the screen."
            );
            break;

          case 404:
            setCampaignInfo(" No existing campaigns were found for this user.");
            break;

          case 500:
            setCampaignError(
              "Something went wrong while attempting to display data on the screen."
            );
            break;

          default:
            setCampaignError(
              "Something went wrong while attempting to display data on the screen."
            );
        }
      } catch (error) {
        setCampaignError(
          "Something went wrong while attempting to display data on the screen."
        );
      }
    };
    fetchCampaignData();
  }, [secretKey]);

  useEffect(() => {
    if (selectedCampaign) {
      setCampaignName(selectedCampaign.name);
      console.log(campaignName, " campaign name");
    }
  }, [selectedCampaign]);

  // fetch responded contacts for a campaign at once and integrate to data grid initially
  useEffect(() => {
    setLoading(true);
    setRespondError(null);
    setRespondInfo(null);
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/records`, {
          params: {
            secret_key: secretKey,
            campaign: campaignName,
          },
          validateStatus: (status) => status >= 200 && status < 500,
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setRecords(data);
            setRespondedTotalRows(data.length);
            break;

          case 400:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          case 404:
            setRespondInfo("No responses to this campaign yet");
            break;

          case 500:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          default:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
        }
      } catch (error) {
        setRespondError(
          "Something went wrong while attempting to display data on to the screen"
        );
      }
      setLoading(false);
    };
    if (isInitialRender.current) {
      isInitialRender.current = false; // Skip the first render
      return;
    }
    fetchData();
  }, [campaignName]);

  useEffect(() => {
    setLoading(true);
    if (isInitialRenderVisit.current) {
      isInitialRenderVisit.current = false; // Skip the first render
      return;
    }
    console.log("selected contact", selectedContact);

    const fetchContactData = async () => {
      console.log("useEffect triggered by selectedContact:", selectedContact);
      const qrCodeId = selectedContact.id;
      try {
        const response = await axios.get(`https://${APIENDPOINT}/related`, {
          params: {
            secret_key: secretKey,
            path: qrCodeId,
          },
          validateStatus: (status) => status >= 200 && status < 500,
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setVisits(data.records);
            console.log("related response", data.records);
            setVisitsTotalRows(data.records.length);
            break;

          case 400:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          case 404:
            setRespondInfo("No visits were found for this contact");
            break;

          case 500:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
            break;

          default:
            setRespondError(
              "Something went wrong while attempting to display data on to the screen"
            );
        }
      } catch (error) {
        return [];
      }
      setLoading(false);
    };

    fetchContactData();
  }, [selectedContact]);

  const campaignFormData = selectedCampaign
    ? [
        { label: "Campaign", value: selectedCampaign.name },
        { label: "Contacts", value: selectedCampaign.total },
        { label: "Created", value: selectedCampaign.timestamp },
        {
          label: "Destination/Landing Page",
          value: selectedCampaign.destination_url,
        },
      ]
    : null;

  const campaignColumns = [
    { field: "name", headerName: "Campaign", flex: 1 },
    { field: "total", headerName: "Contacts", flex: 1 },
  ];

  const campaignRows = campaignsData.map((record, index) => ({
    id: index + 1,
    name: record.name,
    total: record.total,
  }));

  function handleClick(e) {
    setSelectedCampaign(null);
    const selected = campaignsData[e.id - 1];
    console.log("selected campaign", selected);
    setTimeout(() => {
      setSelectedCampaign(selected);
      setSelectedContact(null);
      setSelectedVisit(null);
    }, 0);
  }

  {
    /*responded contacts logic */
  }

  const respondedFormData = selectedContact
    ? [
        { label: "TimeStamp", value: selectedContact.timestamp },
        { label: "qrcode-id", value: selectedContact["id"] },
        {
          label: "envelope_name",
          value: selectedContact["envelope_name"],
        },
        {
          label: "filename_row_unique_id",
          value: selectedContact["filename_row_unique_id"],
        },
        { label: "greeting_name", value: selectedContact.greeting_name },
        {
          label: "ma-addr_line1",
          value: selectedContact["ma-addr_line1"],
        },
        { label: "ma-city", value: selectedContact["ma-city"] },
        { label: "ma-state", value: selectedContact["ma-state"] },
        { label: "ma-zip", value: selectedContact["ma-zip"] },
        { label: "visits", value: selectedContact["visits"] },
      ]
    : null;

  const respondedColumns = [
    { field: "fullname", headerName: "Full Name", flex: 1 },
    { field: "street", headerName: "Street", flex: 1 },
  ];
  const respondedRows = records.map((record, index) => ({
    id: index + 1,
    fullname: record["greeting_name"],
    street: record["ma-addr_line1"],
  }));

  const handleContactClick = (e) => {
    const selected = records[e.id - 1];
    setSelectedContact(selected);
    console.log(selected);
    console.log("inside handle ", selectedContact);
  };

  /* contact visits logic */

  const visitsColumns = [
    { field: "timestamp", headerName: "Timestamp", flex: 1 },
    { field: "qrcode", headerName: "Qrcode-id", flex: 1 },
    { field: "campaign", headerName: "Campaign", flex: 1 },
  ];

  const visitsRows = visits.map((contact, index) => ({
    id: index + 1,
    timestamp: contact["timestamp"],
    qrcode: contact["path"],
    campaign: contact["campaign"],
  }));

  const handleVisitClick = (e) => {
    const selected = visits[e.id - 1];
    setSelectedVisit(selected);
    console.log("handle selected visit", selectedVisit);
  };

  const visitsFormData = selectedVisit
    ? [
        { label: "TimeStamp", value: selectedVisit.timestamp },
        { label: "qrcode-id", value: selectedVisit["path"] },
        {
          label: "country",
          value: selectedVisit["country"],
        },
        {
          label: "user-agent",
          value: selectedVisit["user_agent"],
        },
        { label: "City", value: selectedVisit["city"] },
        {
          label: "Region",
          value: selectedVisit["region"],
        },
      ]
    : null;
  return (
    <div className="dashboard-container">
      {apiError ? (
        <Alert variant="danger">{apiError}</Alert>
      ) : (
        <>
          <h1 className="main-heading">TraQR- Campaign Dashboard</h1>

          <div className="main-container">
            {campaignInfo && (
              <div>
                <Alert variant="success">{campaignInfo}</Alert>
              </div>
            )}
            {!campaignError && !campaignInfo && (
              <div className="grid-container">
                <h4 className="subheading"> Choose a Campaign</h4>
                <CustomDataGrid
                  rows={campaignRows}
                  columns={campaignColumns}
                  totalRows={campaignTotalRows}
                  onRowClick={handleClick}
                />
              </div>
            )}
            {selectedCampaign && (
              <div className="form-container">
                <h4 className="subheading">Campaign Details</h4>
                <EntityDetails inputs={campaignFormData} />
              </div>
            )}
          </div>
        </>
      )}
      {/* Responded Contacts */}

      {selectedCampaign && (
        <div className="main-container">
          {loading && (
            <div className="proof-note">
              <Spinner animation="border" variant="primary" />
              <p>Loading...</p>
            </div>
          )}
          {!loading && respondError && (
            <div
              style={{
                width: "50%",
              }}
            >
              <Alert variant="danger">{respondError}</Alert>
              {respondError}
            </div>
          )}
          {!loading && respondInfo && (
            <div
              style={{
                width: "50%",
              }}
            >
              <Alert variant="primary">{respondInfo}</Alert>
            </div>
          )}
          {!loading && !respondError && !respondInfo && (
            <div className="grid-container">
              <h4 className="subheading">Responded Contacts Choose One </h4>
              <CustomDataGrid
                rows={respondedRows}
                columns={respondedColumns}
                totalRows={respondedTotalRows}
                onRowClick={handleContactClick}
              />
            </div>
          )}

          {respondedFormData && (
            <div className="form-container">
              <h4 className="heading">Contact Details</h4>
              <EntityDetails inputs={respondedFormData} />
            </div>
          )}
        </div>
      )}

      {selectedContact && (
        <div className="main-container">
          {loading && (
            <div className="proof-note">
              <Spinner animation="border" variant="primary" />
              <p>Loading...</p>
            </div>
          )}
          {!loading && visitError && (
            <div
              style={{
                width: "50%",
              }}
            >
              <Alert variant="danger">{respondError}</Alert>
            </div>
          )}
          {!loading && respondInfo && (
            <div
              style={{
                width: "50%",
              }}
            >
              <Alert variant="danfer"> {respondInfo}</Alert>
            </div>
          )}
          {!loading && !respondError && !respondInfo && (
            <div className="grid-container">
              <h4 className="subheading">Contact Visits </h4>
              <CustomDataGrid
                rows={visitsRows}
                columns={visitsColumns}
                totalRows={visitsTotalRows}
                onRowClick={handleVisitClick}
              />
            </div>
          )}

          {visitsFormData && (
            <div className="form-container">
              <h4 className="heading">Visit Details</h4>
              <EntityDetails inputs={visitsFormData} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
