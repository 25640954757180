import React, { useEffect, useState } from "react";
import "../CustomStyle.css";
import { Form, Spinner, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { generatePreviewPdf } from "../utils/PreviewUtils";
import {
  Modal,
  Box,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import * as pdfjsLib from "pdfjs-dist";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const Legend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) =>
    theme === "dark" ? "rgb(14, 17, 23)" : "#fff"};
  padding: 10px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 15px;
  color: ${({ theme }) => (theme === "dark" ? "white" : "#000")};

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: ${({ color }) => color};
  }
`;

// Custom Tooltip Styling
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "black", // Tooltip background color
    color: "white", // Tooltip text color
    fontSize: "10px", // Text font size
    borderRadius: "4px", // Rounded corners
    padding: "8px", // Optional: add padding
  },
});

const ProofTemplate = ({ theme, secretKey }) => {
  let queryParams;

  const [selectedTemplate, setSelectedTemplate] = useState(
    "Agent Letter - Biro"
  );
  const [templateConfig, setTemplateConfig] = useState(null);
  const [template1Config, setTemplate1Config] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [pdfContent, setPdfContent] = useState(null);
  const [pdfBinaryData, setPdfBinaryData] = useState({});
  const [open, setOpen] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfImageSrc, setPdfImageSrc] = useState(null);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [pdfError, setPDFError] = useState(null);
  const [formData, setFormData] = useState({
    "Agent Letter - Arial": {
      prospectsQueryParams: {
        alias: "Mark & Krishna",
        qrCodeContent: "https://example.com",
        id: "1",
      },
      customerQueryParams: {
        realtor_info: "John Doe",
        sigline1: "John Doe, Broker & Owner",
        sigline2: "Your company name",
        sigline3: "CA BRE Lic#: 02184575",
        sigline4: "johndoe@gmail.com",
        cta1: "John",
        cta2: "xxx-xxx-xxxx",
        signature: "John Doe",
      },
      campaignQueryParams: {
        city: "San Ramon",
        buyer_names: "Bob and Mary",
      },
    },
    "Agent Letter - Biro": {
      prospectsQueryParams: {
        alias: "Mark & Krishna",
        qrCodeContent: "https://example.com",
        id: "1",
      },
      customerQueryParams: {
        realtor_info: "Jane Doe",
        sigline1: "Jane Doe, Broker & Owner",
        sigline2: "Your company name",
        sigline3: "CA BRE Lic#: 02184576",
        sigline4: "janedoe@gmail.com",
        cta1: "Jane",
        cta2: "xxx-xxx-xxxx",
        signature: "Jane Doe",
      },
      campaignQueryParams: {
        city: "San Francisco",
        buyer_names: "Alice and Bob",
      },
    },
    "Envelope #10": {
      prospectsQueryParams: {
        alias: "Mark Heinman & Kristina Heinman",
        address1: "5505 Farnam St",
        city: "Omaha",
        state: "NE",
        zip: "68132",
        id: "1",
      },
      customerQueryParams: {
        return_address_line1: " ",
        return_address_line2: " ",
      },
      // No campaignQueryParams here for Envelope template
    },
  });
  const [apiError, setAPIError] = useState(null);
  // const secretKey = window.location.hash.replace("#", "");
  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  const dropdownStyles = {
    borderRadius: "8px",
    border: "1px solid #ccc",
    fontSize: "14px",
    backgroundColor: "#f9f9f9",
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  };

  const handleInputChange = (e, section, key) => {
    const value = e.target.value;

    setFormData((prevState) => {
      return {
        ...prevState,
        [selectedTemplate]: {
          ...prevState[selectedTemplate],
          [section]: {
            ...prevState[selectedTemplate]?.[section],
            [key]: value,
          },
        },
      };
    });
  };

  const handleSelect = (e) => {
    setSelectedTemplate(e.target.value);
  };

  if (selectedTemplate === "Envelope #10") {
    queryParams = {
      prospect: formData[selectedTemplate].prospectsQueryParams,
      customer: formData[selectedTemplate].customerQueryParams,
    };
  } else {
    queryParams = {
      prospect: formData[selectedTemplate].prospectsQueryParams,
      customer: formData[selectedTemplate].customerQueryParams,
      campaign: formData[selectedTemplate].campaignQueryParams,
    };
  }
  queryParams["template"] = selectedTemplate;
  // console.log("query params", queryParams);

  const handleLoadJson = () => {
    fetch("/templateconfig.json")
      .then((response) => response.json())
      .then((data) => setTemplateConfig(data))
      .catch((error) => console.log("failed to load json", error));
  };
  console.log("template config  178", templateConfig);

  // const missingFields = validateRequiredFields(queryParams, templateConfig);

  const validateRequiredFields = async (queryParams, templateConfig) => {
    setValidationErrors({});
    const template = queryParams.template;
    const templateConfigData = templateConfig.templates?.[template] || {};
    const requiredFields = templateConfigData.required_input_fields || [];
    const requiredFieldLabels = templateConfigData.required_field_labels || {};

    console.log("required fields", requiredFieldLabels);

    const errors = {};

    requiredFields.forEach((field) => {
      if (field === "template_name") {
        return;
      }
      let fieldFound = false;
      for (const section in queryParams) {
        if (queryParams[section]?.[field] !== undefined) {
          fieldFound = true;
          if (!queryParams[section][field].trim()) {
            const label = requiredFieldLabels[field] || field;
            errors[field] = `${label} is required`;
            // errors.push(`${label} is required`);
          }
          break;
        }
      }
      if (!fieldFound) {
        const label = requiredFieldLabels[field] || field;
        errors[field] = `${label} is required`;
        // errors.push(`${label} is required`);
      }
    });
    console.log("validation erros", errors);

    setValidationErrors(errors); // Update state or display errors
    return Object.keys(errors).length > 0;
  };

  useEffect(() => {
    console.log("jij");
    fetch("/templateconfig.json")
      .then((response) => response.json())
      .then((data) => {
        // console.log("data", data);
        setTemplate1Config(data);
      })
      .catch((error) => console.error("Error fetching config", error));
  }, []);

  const handleGeneratePreview = async () => {
    setLoading(true);
    if (!template1Config) {
      console.warn("template configuration not loaded yet");
      return;
    }
    console.log("query params", queryParams);
    const missingFields = await validateRequiredFields(
      queryParams,
      template1Config
    );
    console.log("missing fields", missingFields);
    setSuccessMessage(null);
    setError(null);
    if (!missingFields) {
      try {
        const newPdfContent = await generatePreviewPdf(
          queryParams,
          template1Config,
          setPDFError,
          setPdfContent
        );
        console.log("pdf binary data", newPdfContent);
        setPdfBinaryData({ [selectedTemplate]: newPdfContent });
      } catch (error) {
        console.log("error generating pdf", error.message);
      }
    }
  };

  const copyArrayBuffer = (arrayBuffer) => {
    console.log("array buffer", arrayBuffer);
    const copiedBuffer = new ArrayBuffer(arrayBuffer.byteLength);
    console.log("copied buffer", copiedBuffer);
    const view = new Uint8Array(copiedBuffer);
    view.set(new Uint8Array(arrayBuffer));
    return copiedBuffer;
  };

  useEffect(() => {
    const renderPdf = async () => {
      console.log("render");
      if (!pdfBinaryData[selectedTemplate]) return; // Exit if data isn't ready yet

      try {
        console.log(
          "binary data before passing",
          pdfBinaryData[selectedTemplate]
        );
        const pdfDataCopy = copyArrayBuffer(pdfBinaryData[selectedTemplate]);
        console.log("data copy", pdfDataCopy);
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;
        const pdf = await pdfjsLib.getDocument({ data: pdfDataCopy }).promise;
        const page = await pdf.getPage(1); // Display the first page as an image

        const scale = 1.5;
        const viewport = page.getViewport({ scale });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport }).promise;
        setPdfImageSrc(canvas.toDataURL());
      } catch (error) {
        console.log("error rendering pdf:", error.message);
      }
    };
    if (pdfBinaryData[selectedTemplate]) {
      renderPdf();
    }
  }, [pdfBinaryData]);

  useEffect(() => {
    if (pdfContent) {
      setOpen(true);
      setLoading(false);
    }
  }, [pdfContent]);

  useEffect(() => {
    console.log("useeffect envelope data", formData);
  });
  // function for mui modal  close
  const handleClose = () => setOpen(false);

  // function to save template name
  const handleTemplateSave = async () => {
    console.log("template name ", templateName);
    setError("");
    setSuccessMessage("");
    if (!templateName) {
      setError("Need a unique name to save template");
      return;
    }

    try {
      const data = {
        prospect: formData[selectedTemplate].prospectsQueryParams,
        customer: formData[selectedTemplate].customerQueryParams,
        campaign: formData[selectedTemplate].campaignQueryParams || null,
        template: selectedTemplate,
        name: templateName,
      };
      console.log("save template", data);

      const response = await saveCustomizationToDB(data, secretKey);

      if (response.status === 201) {
        const data = response.data;
        // console.log("response from api", response);
        // console.log(data.message);
        setSuccessMessage(`Template ${templateName} saved successfully`);
      } else if (response.status === 400) {
        setError(
          "The operation you attempted has failed. Please contact support."
        );
      } else if (response.status === 409) {
        const message = `A template already exists for the given name ${templateName}. Please choose another name for this template.`;
        setError(message);
      } else if (response.status === 500) {
        setError(
          "The operation you attempted has failed. Please contact support."
        );
      }

      setTemplateName(null);
    } catch (error) {
      console.log("Error saving data:", error);
      setError(error.message);
    }
  };

  const saveCustomizationToDB = async (data, secretKey) => {
    setSuccessMessage(null);
    setError(null);
    try {
      const response = axios.post(
        `https://${APIENDPOINT}/templates`,
        {
          form_data: data,
          secret_key: secretKey,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: (status) => status <= 500,
        }
      );
      console.log("response of save template", response);
      return response;
    } catch (error) {
      console.log("response of save template", response);
      return response;
    }
  };
  const handleDownloadPdf = () => {
    console.log("pdf download data", pdfBinaryData);
    const arrayBuffer = pdfBinaryData[selectedTemplate];
    console.log("arrayBuffer", arrayBuffer);
    if (arrayBuffer) {
      try {
        const blob = new Blob([arrayBuffer], { type: "application/pdf" });
        const link = document.createElement("a");

        link.href = URL.createObjectURL(blob);
        link.download = `${selectedTemplate}_preview`;
        link.click();
        console.log("pdf link", link);
      } catch (error) {
        console.error("Error during PDF download:", error);
      }
    }
  };

  return (
    <div className="dashboard-container">
      <h1 className="main-heading">Proof Template</h1>
      {/* <h3 className="proof-subheading">Choose the template</h3> */}
      <div>
        <FormControl style={{ width: "100%" }}>
          <Typography
            variant="body2"
            className="proof-subheading"
            style={{
              marginBottom: "4px",
              marginTop: "2%",
              fontWeight: "bold",
              fontSize: "22px",
              fontFamily: "inherit", // Ensure same font-family
            }}
          >
            Start Proof with
          </Typography>
          <Select
            defaultValue="Agent Letter - Biro" // Default to first option
            style={dropdownStyles}
            onChange={(e) => handleSelect(e)}
          >
            <MenuItem
              value="Agent Letter - Biro"
              sx={{
                fontStyle: "italic",
                color: "#999",
              }}
            >
              Agent Letter - Biro
            </MenuItem>
            <MenuItem value="Agent Letter - Arial">
              Agent Letter - Arial
            </MenuItem>
            <MenuItem value="Envelope #10">Envelope #10</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="proof-form-container">
        {["Agent Letter - Biro", "Agent Letter - Arial"].includes(
          selectedTemplate
        ) ? (
          <Form className="campaign-form" onSubmit={(e) => e.preventDefault()}>
            <fieldset>
              <legend
                style={{
                  marginBottom: "2%",
                  fontWeight: "bold",
                  color: "#2f539b",
                }}
              >
                Prospect
              </legend>
              <Form.Group
                className="form-group-custom"
                controlId="campaignName"
              >
                <div className="modal-form-field">
                  <Form.Label className="custom-label">
                    Greeting First Name(s)
                  </Form.Label>
                  <CustomTooltip title="Max 25 characters" placement="top">
                    <IconButton
                      sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}
                    >
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.alias ||
                    ""
                  }
                  maxLength={25}
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "alias")
                  }
                />
                {validationErrors && validationErrors.alias && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.alias}</p>{" "}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="custom-label">
                  Redirect to Landing page
                </Form.Label>

                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams
                      ?.qrCodeContent || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "prospectsQueryParams",
                      "qrCodeContent"
                    )
                  }
                  placeholder="https://example.com"
                />
                {validationErrors && validationErrors.qrCodeContent && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>
                      {validationErrors.qrCodeContent}
                    </p>
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="custom-label">ID</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.id || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "id")
                  }
                />
                {validationErrors && validationErrors.id && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.id}</p>{" "}
                  </div>
                )}
              </Form.Group>
            </fieldset>
            <fieldset>
              <legend
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  fontWeight: "bold",
                  color: "#2f539b",
                }}
              >
                Realtor
              </legend>
              <Form.Group
                className="form-group-custom"
                controlId="campaignName"
              >
                <div className="modal-form-field">
                  <Form.Label className="custom-label">Realtor Name</Form.Label>
                  <CustomTooltip title="Max 35 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams
                      ?.realtor_info || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "realtor_info")
                  }
                  maxLength={35}
                />
                {validationErrors && validationErrors.realtor_info && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>
                      {validationErrors.realtor_info}
                    </p>
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <div className="modal-form-field">
                  <Form.Label className="custom-label">
                    Name with Designation
                  </Form.Label>
                  <CustomTooltip title="Max 45 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams?.sigline1 ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "sigline1")
                  }
                  maxLength={45}
                />
                {validationErrors && validationErrors.sigline1 && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.sigline1}</p>{" "}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <div className="modal-form-field">
                  <Form.Label>Company Name</Form.Label>
                  <CustomTooltip title="Max 25 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams?.sigline2 ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "sigline2")
                  }
                  maxLength={25}
                />
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <div className="modal-form-field">
                  <Form.Label>Realtor's License#</Form.Label>
                  <CustomTooltip title="Max 25 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams?.sigline3 ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "sigline3")
                  }
                  maxLength={25}
                />
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <div className="modal-form-field">
                  <Form.Label className="custom-label">
                    Realtor's Email
                  </Form.Label>
                  <CustomTooltip title="Max 45 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams?.sigline4 ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "sigline4")
                  }
                  maxLength={45}
                />
                {validationErrors && validationErrors.sigline4 && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.sigline4}</p>{" "}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <div className="modal-form-field">
                  <Form.Label className="custom-label">
                    Realtor's First Name to Call
                  </Form.Label>
                  <CustomTooltip title="Max 10 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams?.cta1 || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "cta1")
                  }
                  maxLength={10}
                />
                {validationErrors && validationErrors.cta1 && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.cta1}</p>{" "}
                  </div>
                )}
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <div className="modal-form-field">
                  <Form.Label className="custom-label">
                    Call back Phone Number
                  </Form.Label>
                  <CustomTooltip title="Max 15 characters" placement="top">
                    <IconButton sx={{ padding: 0, marginLeft: 1 }}>
                      <HelpOutlineIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </CustomTooltip>
                </div>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams?.cta2 || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "customerQueryParams", "cta2")
                  }
                  maxLength={15}
                />
                {validationErrors && validationErrors.cta2 && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.cta2}</p>{" "}
                  </div>
                )}
              </Form.Group>
            </fieldset>

            <fieldset>
              <legend
                style={{
                  marginBottom: "2%",
                  marginTop: "2%",
                  fontWeight: "bold",
                  color: "#2f539b",
                }}
              >
                Campaign
              </legend>
              <Form.Group
                className="form-group-custom"
                controlId="campaignName"
              >
                <Form.Label className="custom-label">
                  Campaign Target Area
                </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.campaignQueryParams?.city || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "campaignQueryParams", "city")
                  }
                />
                {validationErrors && validationErrors.city && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>{validationErrors.city}</p>{" "}
                  </div>
                )}
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label className="custom-label">
                  Buyer's Name(s)
                </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.campaignQueryParams
                      ?.buyer_names || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "campaignQueryParams", "buyer_names")
                  }
                />
                {validationErrors && validationErrors.buyer_names && (
                  <div style={{ marginTop: "3px" }}>
                    <p style={{ color: "red" }}>
                      {validationErrors.buyer_names}
                    </p>
                  </div>
                )}
              </Form.Group>
            </fieldset>
          </Form>
        ) : (
          <Form className="campaign-form" onSubmit={(e) => e.preventDefault()}>
            <fieldset>
              <legend style={{ marginBottom: "2%", fontWeight: "bold" }}>
                Prospect
              </legend>
              <Form.Group
                controlId="exampleForm.ControlInput1"
                className="form-group-custom"
              >
                <Form.Label className="custom-label">
                  Name(s) on the Envelope
                </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.alias ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "alias")
                  }
                />
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="custom-label">Street</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams
                      ?.address1 || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "address1")
                  }
                />
              </Form.Group>
              <Form.Group className="form-group-custom">
                <Form.Label className="custom-label">City</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.city || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "city")
                  }
                />
              </Form.Group>
              <Form.Group className="form-group-custom">
                <Form.Label className="custom-label">State</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.state ||
                    ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "state")
                  }
                />
              </Form.Group>
              <Form.Group className="form-group-custom">
                <Form.Label className="custom-label">ZIP Code</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.zip || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "zip")
                  }
                />
              </Form.Group>
              <Form.Group className="form-group-custom">
                <Form.Label className="custom-label">ID</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.prospectsQueryParams?.id || ""
                  }
                  onChange={(e) =>
                    handleInputChange(e, "prospectsQueryParams", "id")
                  }
                />
              </Form.Group>
            </fieldset>
            <fieldset>
              <legend style={{ marginBottom: "2%", fontWeight: "bold" }}>
                Realtor's Return Address
              </legend>
              <Form.Group className="form-group-custom">
                <Form.Label>Address Line 1 </Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams
                      ?.return_address_line1 || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "customerQueryParams",
                      "return_address_line1"
                    )
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    formData[selectedTemplate]?.customerQueryParams
                      ?.return_address_line2 || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      e,
                      "customerQueryParams",
                      "return_address_line2"
                    )
                  }
                />
              </Form.Group>
            </fieldset>
          </Form>
        )}

        <Button
          variant="dark"
          size="lg"
          onClick={() => {
            handleLoadJson();
            handleGeneratePreview();
          }}
          className="generate-button"
        >
          Preview Template
        </Button>
      </div>

      {loading && Object.keys(validationErrors || {}).length == 0 && (
        <div className="proof-note">
          <Spinner animation="border" variant="primary" />
          <p>Generating {selectedTemplate} preview</p>
        </div>
      )}

      <Modal
        open={open}
        aria-labelledby="pdf-preview-modal"
        aria-describedby="pdf-preview-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 744,
            maxHeight: "85vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            "@media (max-width: 600px)": {
              width: "95%",
              p: 2,
            },
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 1,
              border: "1px solid #ccc",
              background: "transparent",
              "&:hover": {
                border: "1px solid green",
                color: "green",
                background: "transparent",
              },
              borderRadius: "20%",
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="modal-heading">
            <Typography
              id="pdf-preview-modal"
              variant="h5"
              component="h2"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "1.75rem" },
              }}
            >
              {selectedTemplate}
            </Typography>
          </div>

          <div className="modal-form">
            <Form.Group controlId="templateName">
              <div className="modal-form-field">
                <Form.Label className="custom-label">
                  Name your template
                </Form.Label>
                <CustomTooltip title="Max 25 characters" placement="top">
                  <IconButton
                    sx={{ padding: 0, marginLeft: 1, marginRight: 1 }}
                  >
                    <HelpOutlineIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </CustomTooltip>
              </div>
              <Form.Control
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="success"
              onClick={handleTemplateSave}
              className="modal-button"
              sx={{
                width: "100%", // Ensure the button is full-width on smaller screens
                "@media (min-width: 600px)": {
                  width: "auto", // Revert to auto width on larger screens
                },
              }}
            >
              Save Template
            </Button>
          </div>
          {error && (
            <Alert variant="danger" style={{ margin: "10px" }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert variant="success" style={{ margin: "10px" }}>
              {successMessage}
            </Alert>
          )}
          <div
            className="modal-legends"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {/* Legend */}
            <Legend theme={theme}>
              <LegendItem theme={theme} color="darkviolet">
                <span></span>Prospect
              </LegendItem>
              <LegendItem theme={theme} color="brown">
                <span></span>Realtor
              </LegendItem>
              {selectedTemplate === "Agent Letter - Biro" ||
              selectedTemplate === "Agent Letter - Arial" ? (
                <LegendItem theme={theme} color="#FF6B35">
                  <span></span>Campaign
                </LegendItem>
              ) : null}
            </Legend>

            {/* Download Button */}
            <Button
              variant="success"
              onClick={handleDownloadPdf}
              style={{
                marginLeft: "2%",
                marginBottom: "1.3%",
              }}
            >
              Download PDF
            </Button>
          </div>
          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              margin: "10px",
              padding: "10px",
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                padding: "10px",
                boxSizing: "border-box",
                overflow: "hidden",
                width: "100%",
                maxWidth: "744px",
              }}
            >
              {pdfImageSrc ? (
                <img
                  src={pdfImageSrc}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  alt="PDF preview"
                />
              ) : (
                <p>Loading PDF preview</p>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default ProofTemplate;
