import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Routes, Route, Navigate } from "react-router-dom";
import CreateCampaign from "../Pages/CreateCampaign";
import CampaignsListGrid from "../Pages/CampaignDashboard";
import ProofTemplate from "../Pages/ProofTemplate";
import UploadContacts from "../Pages/UploadContacts";
import "../CustomStyle.css";
import PrintContent from "../Pages/PrintContent.jsx";
import { Alert } from "react-bootstrap";
const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [secretKey, setSecretKey] = useState(null);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    setAuthError(null);

    const urlSecretKey = window.location.hash.replace("#", "");
    const storedSecretKey = sessionStorage.getItem("login_code");

    if (urlSecretKey) {
      setSecretKey(urlSecretKey); // If secretKey is in URL, use it
      sessionStorage.setItem("login_code", urlSecretKey); // Store it in sessionStorage
    } else if (storedSecretKey) {
      setSecretKey(storedSecretKey); // If secretKey is in sessionStorage, use it
    } else {
      setAuthError("Please visit the URL provided to you."); // Show error if neither are available
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  if (authError) {
    return (
      <Alert variant="danger" style={{ width: "80%", margin: "4%" }}>
        {authError}
      </Alert>
    );
  }
  if (secretKey === null) {
    return <div>Loading...</div>; // You can replace this with a more sophisticated loading spinner or message
  }
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Set to 100vh for full height */}

      <div
        style={{
          width: isSidebarOpen
            ? window.innerWidth <= 768
              ? "70%" // Fixed width for mobile devices
              : "20%" // Percentage width for larger screens
            : "0",
          transition: "width 0.3s ease",
        }}
      >
        <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>

      <div
        style={{
          flexGrow: 1,
          overflowY: "auto",
          margin: isSidebarOpen
            ? window.innerWidth <= 768
              ? "20px 10px 0 10px" // Adjust margins for mobile when sidebar is open
              : "0 10px 0 20px" // Default margins for larger screens
            : "40px 20px 0 20px", // Margin when sidebar is closed
          transition: "margin 0.3s ease",
        }}
      >
        {/* Optional: Add overflow for scrolling */}
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/campaign_dashboard" replace />}
          />
          <Route
            path="/create_campaign"
            element={<CreateCampaign secretKey={secretKey} />}
          />
          <Route
            path="/upload_contacts"
            element={<UploadContacts secretKey={secretKey} />}
          />
          <Route
            path="/campaign_dashboard"
            element={<CampaignsListGrid secretKey={secretKey} />}
          />
          <Route
            path="/proof_template"
            element={<ProofTemplate secretKey={secretKey} />}
          />
          <Route
            path="/generate_print_collaterals"
            element={<PrintContent secretKey={secretKey} />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default AppLayout;
