// pdf generation
import axios from "axios";

export const isURL = (value) => {
  try {
    const url = new URL(value);
    return Boolean(url.protocol && url.host);
  } catch (e) {
    return false;
  }
};
const constructPreviewLink = (queryParams, config) => {
  // accepting queryParams(3 or 4) and selected template config details as a parameter
  // console.log("construct link");
  try {
    let constructedLink = config.base_url;
    const quotedParams = {};

    Object.entries(queryParams).forEach(([key, value]) => {
      // key holds each params and value holds each params details
      // console.log("key", key);
      // console.log("value", value);

      if (key === "prospect" || key === "customer" || key === "campaign") {
        Object.entries(value).forEach(([subKey, subValue]) => {
          if (isURL(subValue)) {
            quotedParams[subKey] = encodeURIComponent(subValue); // encoding url(/, & ,space,:) value to make it URL-safe
          } else {
            // console.log(key, subKey, subValue);
            quotedParams[subKey] = encodeURIComponent(
              `<span class="${key}">${subValue}</span>` // encoding html span tag assigned with specific class
            );
          }
          // console.log("quoted", quotedParams);
        });
      }
    });

    const queryString = Object.entries(quotedParams)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");

    // console.log(queryString);
    constructedLink += "?" + queryString;
    console.log("constructed link", constructedLink);
    return constructedLink;
  } catch (error) {
    console.error("Error constructing preview link:", error);
    throw new Error(`Value Error: ${error.message}`);
  }
};

const triggerLambda = async (constructedLink, templateConfig, queryParams) => {
  try {
    const lambdaUrl =
      templateConfig.templates[queryParams.template].lambda_url +
      encodeURIComponent(constructedLink);
    console.log("lambda ", lambdaUrl);
    const response = await fetch(lambdaUrl);
    console.log("response", response);
    const data = await response.arrayBuffer();
    // const uint8View = new Uint8Array(data);
    // console.log("unit view", uint8View);
    // console.log("First few bytes:", uint8View.slice(0, 20)); // View the first 10 bytes
    // const blob = new Blob([data], { type: "application/pdf" });
    // const url = URL.createObjectURL(blob);
    // window.open(url); // Opens a new tab to view the PDF

    // const downloadBlob = (blob, filename) => {
    //   const link = document.createElement("a");
    //   link.href = URL.createObjectURL(blob);
    //   link.download = filename;
    //   link.click();
    //   console.log("blob", link);
    // };
    // downloadBlob(new Blob([data]), "downloadedFile.pdf");

    console.log("data", data);
    // console.log(" data length", data.byteLength);
    // if (
    //   data.byteLength !== parseInt(response.headers.get("content-length"), 10)
    // ) {
    //   console.error("Byte length mismatch, possible data corruption.");
    //   return;
    // } else {
    //   console.log("content and byte length have same length");
    // }
    return data;
  } catch (error) {
    console.error("Error generating preview of print collateral:", error);
    throw new Error("Error generating preview of print collateral");
  }
};

export const generatePreviewPdf = async (
  queryParams,
  templateConfig,
  setValidationErrors,
  setPdfContent
) => {
  //console.log("generate pdf", queryParams);
  //   console.log("geerate", templateConfig);
  //   console.log("geerate", setValidationErrors);
  //   console.log("geerate", setPdfContent);

  try {
    const template = queryParams.template; // selected template
    // console.log("template", template);
    // console.log("config", templateConfig.templates);
    const config = templateConfig.templates[template]; // object details of selected template
    console.log("config", config);

    if (!config) {
      throw new Error("Invalid template type");
    }

    const errors = [];

    // Iterate over each section in  query params(prospect,customer,campaign)
    Object.entries(queryParams).forEach(([key, params]) => {
      console.log("object", params);
      if (typeof params === "object") {
        // ensure each query param is object
        Object.entries(params).forEach(([field, value]) => {
          // console.log("field", field, "value", value);
          if (field.includes("qrCodeContent") && !isURL(value)) {
            errors.push(
              "Trackable URL provided is invalid. Please provide the URL in the format specified in the field."
            );
          }
        });
      }
    });

    if (errors.length > 0) {
      setValidationErrors(errors); // need to display this error in previewcollateral page
      return;
    }

    // Clear previous errors
    setValidationErrors([]);

    // Construct preview link and trigger the  Lambda function
    const constructedLink = constructPreviewLink(queryParams, config);
    const content = await triggerLambda(
      constructedLink,
      templateConfig,
      queryParams
    );

    setPdfContent(content);
    console.log("content", content);
    return content;
  } catch (error) {
    console.error("Error generating PDF preview:", error);
  }
};
