// templateConfig.js

export const templatesData = {
  "Agent Letter - Biro": {
    formData: {
      prospectsQueryParams: {
        alias: "Mark & Krishna",
        qrCodeContent: "https://craigproctor.com/coaching/about-memberships",
        id: "1",
      },
      customerQueryParams: {
        realtor_info: "John Doe",
        sigline1: "John Doe, Broker & Owner",
        sigline2: "Your company name",
        sigline3: "CA BRE Lic#: 02184575",
        sigline4: "johndoe@gmail.com",
        cta1: "John",
        cta2: "xxx-xxx-xxxx",
        signature: "John Doe",
      },
      campaignQueryParams: {
        city: "San Ramon",
        buyer_names: "Bob and Mary",
      },
    },
  },
  "Agent Letter - Arial": {
    formData: {
      prospectsQueryParams: {
        alias: "Alice & Bob",
        qrCodeContent: "https://example.com",
        id: "2",
      },
      customerQueryParams: {
        realtor_info: "Jane Smith",
        sigline1: "Jane Smith, Broker & Owner",
        sigline2: "Her company name",
        sigline3: "CA BRE Lic#: 12345678",
        sigline4: "janesmith@gmail.com",
        cta1: "Jane",
        cta2: "xxx-xxx-xxxx",
        signature: "Jane Smith",
      },
      campaignQueryParams: {
        city: "Los Angeles",
        buyer_names: "Alice and Bob",
      },
    },
  },
  "Envelope #10": {
    formData: {
      prospectsQueryParams: {
        alias: "Mark Heinman & Kristina Heinman",
        address1: "5505 Farnam St",
        city: "Omaha",
        state: "NE",
        zip: "68132",
        id: "1",
      },
      customerQueryParams: {
        return_address_line1: " ",
        return_address_line2: " ",
      },
    },
  },
};
