/* eslint-disable react/prop-types */
import React from "react";
import { Paper } from "@mui/material";
import {
  DataGrid,
  GridPagination,
  useGridApiContext,
  useGridSelector, // used to retrieve specific pieces of state from datagrid
  gridPageCountSelector,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";

// function Pagination({ page, onPageChange, className }) {
//   const apiRef = useGridApiContext(); //provides access to datagrid internal api, helps to interact with the grids internal state
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector); // getting total number of pages

//   return (
//     <MuiPagination
//       color="primary"
//       className={className}
//       count={pageCount}
//       page={page + 1} //current page
//       onChange={(event, newPage) => {
//         onPageChange(event, newPage - 1);
//       }}
//     />
//   );
// }

// function CustomPagination() {
//   return <GridPagination ActionsComponent={Pagination} />;
// }
const CustomDataGrid = ({ rows, columns, onRowClick }) => {
  const paginationModel = { page: 0, pageSize: 5 };
  return (
    <Paper sx={{ height: "auto" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{ pagination: { paginationModel } }}
        pageSizeOptions={[5, 10]}
        onRowClick={onRowClick}
      />
    </Paper>
  );
};

export default CustomDataGrid;
