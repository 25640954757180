import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import "../CustomStyle.css";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import axios from "axios";
import CustomDataGrid from "../components/CustomDataGrid";
import EntityDetails from "../components/EntityDetails";

export function validateUrl(url) {
  const regex = new RegExp(
    /^(https|ftp):\/\/([A-Z0-9][A-Z0-9-]{0,61}[A-Z0-9]\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|(\[?[A-F0-9]*:[A-F0-9:]+\]?)\b(?::\d+)?(\/[A-Z0-9+&@#\/%=~_|$?!:,.]*)?$/i
  );

  // Basic regex checks
  if (!regex.test(url)) {
    return false;
  }

  try {
    const parsedUrl = new URL(url);

    // Check if the protocol is valid
    if (!["http:", "https:", "ftp:"].includes(parsedUrl.protocol)) {
      return false;
    }

    // Exclude localhost, loopback, or local network addresses
    const excludedHosts = ["localhost", "127.0.0.1", "::1"];
    if (
      excludedHosts.includes(parsedUrl.hostname) ||
      parsedUrl.hostname.startsWith("192.168.") ||
      parsedUrl.hostname.startsWith("10.")
    ) {
      return false;
    }

    // Check for valid TLD
    const tld = parsedUrl.hostname.split(".").pop();
    if (tld.length < 2) {
      return false;
    }

    return true;
  } catch (e) {
    // If URL parsing fails, it's not a valid URL
    return false;
  }
}

function CreateCampaign({ secretKey }) {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignTotalRows, setCampaignTotalRows] = useState(0);
  const [refreshCampaigns, setRefreshCampaigns] = useState(true);
  const [campaignName, setCampaignName] = useState("");
  const [destinationUrl, setDestinationUrl] = useState("");
  const [error, setError] = useState(null);
  const [info, setInfo] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [payLoad, setPayLoad] = useState({});
  const [apiError, setAPIError] = useState(null);
  // const [secretKey, setSecretKey] = useState(
  //   window.location.hash.replace("#", "")
  // );
  const APIENDPOINT = "developer19-linqr.reachpersona.com";
  // fetch campaigns data once and integrate to data grid initially

  // useEffect(() => {
  //   console.log("useEffect called");
  //   console.log("secretKey useEffect", secretKey);

  //   // Ensure secretKey is loaded from sessionStorage if not set
  //   if (!secretKey) {
  //     const loginCode = sessionStorage.getItem("login_code");
  //     console.log("login code", loginCode);
  //     if (!loginCode) {
  //       setAPIError("Please visit the URL provided to you.");
  //     } else {
  //       setSecretKey(loginCode); // State update won't be immediate
  //     }
  //   } else {
  //     sessionStorage.setItem("login_code", secretKey); // Ensure it's stored
  //   }
  // }, [secretKey]);

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/campaigns`, {
          params: {
            secret_key: secretKey,
          },
        });
        switch (response.status) {
          case 200:
            const data = await response.data;
            setCampaignsData(data.records);
            setCampaignTotalRows(data.records.length);
            break;

          case 400:
            return null;

          case 404:
            return null;

          case 500:
            return null;

          default:
            return null;
        }
      } catch (error) {
        return null;
      }
    };
    console.log("refresh before");

    if (refreshCampaigns) {
      fetchCampaignData();
      setRefreshCampaigns(false);
      console.log("refresh", refreshCampaigns);
    }
  }, [refreshCampaigns]);

  useEffect(() => {
    if (!campaignName || !destinationUrl) {
      setIsButtonDisabled(true);
      return;
    }
    setIsButtonDisabled(false);
  }, [campaignName, destinationUrl]);

  const campaignFormData = selectedCampaign
    ? [
        { label: "Campaign", value: selectedCampaign.name },
        { label: "Contacts", value: selectedCampaign.total },
        { label: "Created", value: selectedCampaign.timestamp },
        {
          label: "Destination/Landing Page",
          value: selectedCampaign.destination_url,
        },
      ]
    : null;

  const campaignColumns = [
    { field: "name", headerName: "Campaign", flex: 1 },
    { field: "total", headerName: "Contacts", flex: 1 },
  ];

  const campaignRows = campaignsData.map((record, index) => ({
    id: index + 1,
    name: record.name,
    total: record.total,
  }));

  if (campaignsData.length < 0) {
    return (
      <div
        className="upload-contacts-container"
        style={{
          backgroundColor: "#ff9999 ",
          padding: "10px",
          borderRadius: "10px",
          color: "#ffffff",
        }}
      >
        <h2>You do not have any existing campaigns</h2>
      </div>
    );
  }
  function handleClick(e) {
    setSelectedCampaign(null);
    const selected = campaignsData[e.id - 1];
    setTimeout(() => {
      setSelectedCampaign(selected);
    }, 0);
  }

  const handleSubmit = async () => {
    setError(null);
    setInfo(null);
    if (destinationUrl && !validateUrl(destinationUrl)) {
      setError(
        `Please enter a valid URL. Required format: https://yourcompany.com/landingPageX`
      );
      return;
    }
    if (campaignName && destinationUrl) {
      const payload = {
        secret_key: secretKey,
        name: campaignName,
        destination_url: destinationUrl,
      };
      // setPayLoad(payload);
      await createCampaign(payload);
    }
    console.log("payload", payLoad);
  };

  const createCampaign = async (payload) => {
    console.log("payload1", payload);
    try {
      const response = await axios.post(
        `https://${APIENDPOINT}/campaigns`,
        payload,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          validateStatus: (status) => status <= 500,
        }
      );

      if (response.status === 201) {
        setInfo(`A campaign for ${payload.name} was created successfully`);
        setCampaignName("");
        setDestinationUrl("");
        setRefreshCampaigns(true);
        return;
      } else if (response.status === 409) {
        setError(
          `A campaign under ${payload.name} already exists for this user`
        );
      } else if (response.status === 500) {
        console.log("error", response.statusText);
        setError(
          "The operation you attempted has failed. Please contact support."
        );
      }
    } catch (error) {
      if (error.response) {
        console.log("error", error);
        console.log("error response", error.response);
      }

      setError(
        "The operation you attempted has failed. Please contact support."
      );
    }
  };

  const handleCampaignChange = (e) => {
    setCampaignName(e.target.value);
  };

  const handleDestinationChange = (e) => {
    setDestinationUrl(e.target.value);
  };

  return (
    <>
      {apiError ? (
        <div style={{ margin: " 2% 2%", width: "90%", minWidth: "300px" }}>
          <Alert variant="danger">{apiError}</Alert>
        </div>
      ) : (
        <>
          <div className="create-campaign-container">
            <h1 className="create-campaign-title">TraQR-Create Campaign</h1>

            <Form className="campaign-form">
              <Form.Group
                className="form-group-custom"
                controlId="campaignName"
              >
                <Form.Label className="custom-label">
                  Name Your New Campaign
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="For ex:Postcard-ForHayward.Empty/duplicate campaign names are not allowed."
                  value={campaignName}
                  onChange={(e) => handleCampaignChange(e)}
                />
              </Form.Group>
              <Form.Group
                className="form-group-custom"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="custom-label">
                  Enter the URL value to redirect the user to
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="For ex: https://yourcompany.com/landingPageX- Cannot be modified later."
                  value={destinationUrl}
                  onChange={(e) => handleDestinationChange(e)}
                />
              </Form.Group>
              <Button
                variant="dark"
                size="lg"
                onClick={() => handleSubmit()}
                className="create-campaign-button"
                disabled={isButtonDisabled}
              >
                Create Campaign
              </Button>
            </Form>
            {error && <Alert variant="danger">{error}</Alert>}
            {info && <Alert variant="success">{info}</Alert>}
          </div>

          <div className="create-main-container">
            <div
              className={`create-grid-form ${
                selectedCampaign ? "grid-to-left" : "grid-centered"
              } `}
            >
              <div className="create-grid-container">
                <h3 style={{ color: "#2f539b" }}>Existing Campaigns</h3>
                <CustomDataGrid
                  rows={campaignRows}
                  columns={campaignColumns}
                  totalRows={campaignTotalRows}
                  onRowClick={handleClick}
                />
              </div>
              {selectedCampaign && (
                <div className="create-form-container">
                  <h3 style={{ color: "#2f539b" }}>Campaign Details</h3>
                  <EntityDetails inputs={campaignFormData} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CreateCampaign;
