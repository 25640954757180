import React, { useState, useEffect, useRef } from "react";
import CustomDataGrid from "../components/CustomDataGrid";
import EntityDetails from "../components/EntityDetails";
import Button from "react-bootstrap/Button";
import "../CustomStyle.css";
import axios from "axios";
import { Alert } from "react-bootstrap";
import JSZip from "jszip";
import QRCode from "qrcode"; // For QR code generation
import { saveAs } from "file-saver"; // For downloading files
import {
  FaTrashAlt,
  FaCheckCircle,
  FaCloudUploadAlt,
  FaDownload,
} from "react-icons/fa";

const UploadContacts = ({ secretKey }) => {
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignsData, setCampaignsData] = useState([]);
  const [campaignError, setCampaignError] = useState(null);
  const [campaignTotalRows, setCampaignTotalRows] = useState(0);
  const [refreshCampaigns, setRefreshCampaigns] = useState(true);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [zipFile, setZipFile] = useState(null);
  const fileInputRef = useRef(null);

  // let secretKey = window.location.hash.replace("#", "");
  const APIENDPOINT = "developer19-linqr.reachpersona.com";

  useEffect(() => {
    // if (!secretKey) {
    //   const loginCode = sessionStorage.getItem("login_code");
    //   if (!loginCode) {
    //     setCampaignError("Please visit the URL provided to you.");
    //   } else {
    //     secretKey = loginCode;
    //   }
    // } else {
    //   sessionStorage.setItem("login_code", secretKey);
    // }

    const fetchCampaignData = async () => {
      try {
        const response = await axios.get(`https://${APIENDPOINT}/campaigns`, {
          params: {
            secret_key: secretKey,
          },
        });

        switch (response.status) {
          case 200:
            const data = await response.data;
            setCampaignsData(data.records);
            setCampaignTotalRows(data.records.length);
            break;

          case 400:
            return null;

          case 404:
            return null;

          case 500:
            return null;

          default:
            return null;
        }
      } catch (error) {
        return null;
      }
    };
    if (refreshCampaigns) {
      fetchCampaignData();
      setRefreshCampaigns(false);
    }
  }, [refreshCampaigns]);

  const campaignFormData = selectedCampaign
    ? [
        { label: "Campaign", value: selectedCampaign.name },
        { label: "Contacts", value: selectedCampaign.total },
        { label: "Created", value: selectedCampaign.timestamp },
        {
          label: "Destination/Landing Page",
          value: selectedCampaign.destination_url,
        },
      ]
    : null;

  const campaignColumns = [
    { field: "name", headerName: "Campaign", flex: 1 },
    { field: "total", headerName: "Contacts", flex: 1 },
  ];

  const campaignRows = campaignsData.map((record, index) => ({
    id: index + 1,
    name: record.name,
    total: record.total,
  }));

  if (campaignsData.length < 0) {
    return (
      <div
        className="upload-contacts-container"
        style={{
          backgroundColor: "#ff9999 ",
          padding: "10px",
          borderRadius: "10px",
          color: "#ffffff",
        }}
      >
        <h2>No Existing campaigns found for this user. </h2>
      </div>
    );
  }
  function handleClick(e) {
    setSelectedCampaign(null);
    const selected = campaignsData[e.id - 1];
    setTimeout(() => {
      setSelectedCampaign(selected);
    }, 0);
  }

  /* file  upload modal */

  // Reset error message
  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];

    setUploadError("");

    if (uploadedFile) {
      // Check file type
      if (uploadedFile.type !== "text/csv") {
        setUploadError("Only CSV files are allowed.");
        setFile(null);
        event.target.value = null;
        return;
      }

      // Check file size (2 MB = 2 * 1024 * 1024 bytes)
      if (uploadedFile.size > 2 * 1024 * 1024) {
        setUploadError("File size should not exceed 2 MB.");
        setFile(null);

        event.target.value = null;
        return;
      }

      setFile(uploadedFile);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const data = {
        customer_id: campaignsData[0].customer_id,
        campaign: selectedCampaign.name,
        destination_url: selectedCampaign.destination_url,
      };

      const csvData = await sendToAPI(file, data);
      console.log("response data", csvData);

      if (csvData && Array.isArray(csvData)) {
        const rowsWithId = csvData.map((item) => ({
          ...item,
          id: item.ID, // Map `ID` to `id`
        }));
        const dynamicColumns = Object.keys(csvData[0]).map((key) => ({
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          width: 150,
        }));

        setColumns(dynamicColumns);
        setRows(rowsWithId);
        setTotalRows(csvData.length);
        const trackableUrls = csvData.map((row) => row["o.rp.trackableurl"]);
        generateQRCodesAndZip(csvData, columns, rows);
      }

      setFile(null);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const sendToAPI = async (csvFile, payload) => {
    try {
      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      formData.append("file", csvFile);

      const response = await axios.post(
        `https://${APIENDPOINT}/bulk`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          validateStatus: (status) => status <= 500,
        }
      );

      if (response.status === 200) {
        const data = await response.data;
        console.log(data);
        setInfo("Data fetched from API successfully");
        setRefreshCampaigns(true);
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = null;
        }
        return data;
      } else if (response.status === 400) {
        setError(response.data.message);
        return;
      } else if (response.status === 500) {
        setError(
          "The operation you attempted has failed. Please contact support."
        );
        return;
      } else {
        setError(
          "The operation you attempted has failed. Please contact support."
        );
        return;
      }
    } catch (error) {
      console.log(error);
      setError(
        "The operation you attempted has failed. Please contact support."
      );
      return;
    }
  };

  const generateQRCodesAndZip = async (csvData) => {
    const zip = new JSZip();
    const qrZip = new JSZip();

    const qrPath = "qrcodes";

    for (const row of csvData) {
      const trackableUrl = row["o.rp.trackableurl"];
      const qrCodeName = row["o.rp.qrcode_img"];

      if (trackableUrl === "Error") {
        continue;
      }
      try {
        const qrCodeDataUrl = await QRCode.toDataURL(trackableUrl, {
          scale: 10,
          margin: 1,
        });
        console.log("qrcode data url", qrCodeDataUrl);
        const qrBlob = await (await fetch(qrCodeDataUrl)).blob();

        qrZip.file(`${qrCodeName}.png`, qrBlob);
      } catch (error) {}
    }

    // Convert CSV data to string and add to ZIP
    const csvContent = csvDataToCsvString(csvData);
    const csvBlob = new Blob([csvContent], { type: "text/csv" });

    zip.file("processed_contacts.csv", csvBlob);

    // Create the QR code zip and add it to the main zip
    const qrZipBlob = await qrZip.generateAsync({ type: "blob" });
    zip.file("qrcodes.zip", qrZipBlob);

    // Generate the ZIP file as a blob
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Generate a formatted file name
    const campaignName = selectedCampaign.name || "unknown";
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "_");
    const zipFileName = `campaign_${campaignName}_bulk_upload_results_${timestamp}.zip`;

    // Save ZIP file to state
    setZipFile({ blob: zipBlob, name: zipFileName });
  };

  const csvDataToCsvString = (data) => {
    const headers = Object.keys(data[0]).join(","); // Get column headers from first row
    const rows = data
      .map((row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? value : value)) // Don't add quotes for strings
          .join(",")
      )
      .join("\n");

    return `${headers}\n${rows}`;
  };

  return (
    <div className="upload-contacts-container">
      {campaignError ? (
        <Alert variant="danger">{campaignError}</Alert>
      ) : (
        <>
          <h1 className="main-heading">TraQR-Upload Contacts</h1>

          <h4 className="subheading-1">Select a campaign to upload contacts</h4>
          <p className="upload-para">
            Mandatory CSV columns: ID, envelope_name, greeting_name,
            ma-addr_line1, ma-city, ma-state, ma-zip.
          </p>
          <div className="main-container ">
            <div className="grid-container">
              {/* <h4 className="subheading">Choose a Campaign</h4> */}
              <CustomDataGrid
                rows={campaignRows}
                columns={campaignColumns}
                totalRows={campaignTotalRows}
                onRowClick={handleClick}
              />
            </div>
            {selectedCampaign && (
              <div
                className="form-container"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* <h4 className="heading">Campaign Details</h4> */}
                <EntityDetails inputs={campaignFormData} />

                <div className="file-upload-container">
                  <p>
                    {`Upload CSV File For Campaign ${selectedCampaign.name}`}
                  </p>
                  <div className="upload-area">
                    <label htmlFor="fileInput" className="upload-label">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          padding: "10px",
                          backgroundColor: "white",
                          borderRadius: "15px",
                        }}
                      >
                        <FaCloudUploadAlt size={20} color="black" />
                        <span style={{ color: "black", fontWeight: "bold" }}>
                          Browse
                        </span>
                      </div>
                      <span style={{ marginLeft: "8px" }}>
                        or drag and drop
                      </span>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      accept=".csv"
                      onChange={handleFileChange}
                      className="file-input"
                      ref={fileInputRef}
                    />
                  </div>

                  {uploadError && (
                    <p className="error-message">{uploadError}</p>
                  )}

                  {file && (
                    <div className="file-list">
                      <div className="file-item">
                        <span>{file.name}</span>
                        <FaCheckCircle className="icon success" />
                        <FaTrashAlt
                          className="icon delete"
                          onClick={handleRemoveFile}
                        />
                      </div>
                    </div>
                  )}

                  <Button
                    variant="dark"
                    className="upload-button"
                    onClick={handleUpload}
                    disabled={!file}
                  >
                    Upload
                  </Button>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}

                {info && (
                  <div>
                    <Alert variant="success">{info}</Alert>
                    <div className="container-csv ">
                      <CustomDataGrid
                        rows={rows}
                        columns={columns}
                        totalRows={totalRows}
                      />
                    </div>
                  </div>
                )}

                {zipFile && (
                  <Button
                    variant="outline-success"
                    className="upload-button"
                    onClick={() => saveAs(zipFile.blob, zipFile.name)}
                  >
                    <FaDownload size={20} style={{ marginRight: "8px" }} />
                    Download ZIP File
                  </Button>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UploadContacts;
